export const setResourcesPath = (id) => {
  switch (id) {
    case 1:
      return 'experts';
    case 2:
      return 'community';
    case 3:
      return 'guidebooks';
    case 4:
      return 'faqs';
    default:
      return 'experts';
  }
};

export const setTitleFromPath = (name) => name.substr(1).charAt(0).toUpperCase() + name.slice(2);

export const calculationLoanEMI = (ir, np, pv, fv = 0) => {
  let pmt, pvif, type;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return Math.abs(pmt);
};

export const calculationTotalInterest = (loanEMI, loanTenure) => loanEMI * loanTenure * 12;

export const getCalculation = (homeLoanAmount = 1, interestRate = 1, loanTenure = 1) => {
  const periodInmonths = loanTenure * 12;
  const rateInMonth = interestRate / 12 / 100;

  let array = [];
  let totalInterest = 0;

  for (let i = 1; i <= loanTenure * 12; i++) {
    let data = {};

    if (array.length) {
      data = {
        month: i,
        amount: array[i - 2].remainingPrincipalAmound,
        instalment: calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount),
        interest: Math.round((array[i - 2].remainingPrincipalAmound * interestRate) / 1200),
        principalRepaid: Math.ceil(
          calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount) -
            (array[i - 2].remainingPrincipalAmound * interestRate) / 1200
        ),
        remainingPrincipalAmound:
          array[i - 2].remainingPrincipalAmound -
          (calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount) -
            (array[i - 2].remainingPrincipalAmound * interestRate) / 1200)
      };
    } else {
      data = {
        month: i,
        amount: +homeLoanAmount,
        instalment: calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount),
        interest: Math.round((homeLoanAmount * interestRate) / 1200),
        principalRepaid:
          calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount) -
          (homeLoanAmount * interestRate) / 1200,
        remainingPrincipalAmound:
          homeLoanAmount -
          (calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount) -
            (homeLoanAmount * interestRate) / 1200)
      };
    }

    array.push(data);
  }

  totalInterest =
    calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount) * loanTenure * 12 -
    homeLoanAmount;

  return Math.round(totalInterest);
};


export function numToString(value) {
  let num = new Intl.NumberFormat('en-US').format(value);
  return `${num}`;
}

export const StringToNum = (number) => {
  let num = number
  if (typeof num === 'number') return num
  if (isNaN(parseInt(num.replace(',', '')))) {
    return false
  }
  return parseInt(num.replace(',', ''))
}