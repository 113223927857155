import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      dashboard: 'Dashboard',
      forgot: 'Forgot',
      logIn: 'Log In',
      signIn: 'Sign in',
      signOut: 'Sign Out',
      welcomeBack: 'Welcome back, you’ve been missed!',
      orLoginWith: 'or Log In with',
      isNew: 'New to Daleel?',
      register: 'Register',
      createAccount: 'Create an account to continue!',
      alreadyHavaAccount: 'Already have an account?',
      next: 'Next',
      buildYourProfile: 'Build your profile',
      address: 'Address',
      skipForNow: 'Skip for now',
      step1Of2: 'Step 1/2',
      step2Of2: 'Step 2/2',
      connect: 'Connect',
      congratulations: 'Congratulations!',
      phoneNumber: 'Phone Number',
      connectHealthData: 'Connect health data',
      startYourWealth: 'Start your wealth and health journey today!',
      youAreAllSet: 'You are all set! Your financial journey starts today',
      homePage: 'Home page',
      orRegisterWith: 'or Register with',
      forgotPassword: 'Forgot password?',
      enterYourEmail: 'Enter your email to recover your password',
      recoverViaPhone: 'Recover via phone number',
      backToLogin: 'back to log in',
      sendLink: 'Send Link',
      sendCode: 'Send Code',
      recoverViaEmail: 'Recover via email',
      enterYourPhone: 'Enter your phone number to recover your password',
      authenticationCode: 'An authentication code has been sent to',
      resend: 'Resend',
      resetPassword: 'Reset Password',
      atLeastCharacters: 'At least 8 characters, with uppercase and lowercase letters',
      wentWrong: 'Oops! Something went wrong.',
      contactSupport: 'Contact Support',
      wentWrongText:
        'We encountered and error while trying to connect our server. Please retry or contact support.',
      dontReceiveCode: 'Don’t receive the code',
      verify: 'Verify',
      verificationCode: 'Verification code',
      verificationCodeSent: 'An verification code has been sent to',
      yourPasswordReset: 'Your password has been successfully reset',
      done: 'Done',
      error: 'Error',
      somethingWentWrong: 'Something went wrong. Please try again.',
      retry: 'Retry',
      emailVerified: 'Your email is verified',
      dataCannotConnected: 'The data cannot be connected using the browser. ',
      name: 'Name',
      surname: 'Surname',
      yourScore: 'Your Score',
      points: 'Points',
      searchCompare: 'Search & Compare',
      creditCard: 'Credit Cards',
      personalLoans: 'Personal Loans',
      carLoans: 'Car Loans',
      depositAccount: 'Deposit Accounts',
      savingAccounts: 'Saving Accounts',
      crypto: 'Crypto',
      rememberPassword: 'Remember password?',
      sentPassword:
        'We have sent a password reset code by {{method}} to {{email}}. Enter it below to reset your password.',
      readMore: 'Read more',
      markAllRead: 'Mark all read',
      startQuiz: 'Start quiz',
      noNotificationYet: 'No notifications yet',
      notifications: 'Notifications',
      allCreditCards: 'All credit cards',
      annualFee: 'Annual Fee',
      interestRate: 'Interest Rate',
      creditCards: 'Credit cards',
      mostPopular: 'Most Popular',
      cashBackCards: 'Cashback Cards',
      travelCards: 'Travel Cards',
      filter: 'Filter',
      filterDescription: 'You’re looking for a credit card to help you:',
      clearAll: 'Clear All',
      filters: {
        points: 'Earn points, miles and travel offers',
        cashback: 'Get cashback',
        interest: 'Save money on interest',
        manage: 'Better way to manage finances and spending'
      },
      validation: {
        required: 'Field is required',
        email: 'Email is not valid',
        password: 'Incorrect password',
        passwordMismatch: 'Password mismatch',
        tooShort: 'Too short',
        fieldMustBeChecked: 'Field must be checked',
        invalidPassword: 'Invalid password',
        passwordRules: `The password should contain:
   · At least 8 characters
   · 5 or more unique characters
   · At least 3 of the following: uppercase, lowercase, numeric, or special characters.`
      },
      resources: {
        unlockPoints: 'Unlock points',
        meetOurExperts: 'Meet Our Experts',
        about: 'About',
        yourFAQsAnswered: 'Your FAQs answered',
        guidebooks: 'Guidebooks',
        areaOfExpertise: 'Area of expertise:',
        takeQuiz: 'Take Quiz'
      },
      calculator: {
        amount: 'Amount',
        homeLoan: 'Home Loan',
        loanCalculator: 'Loan Calculator',
        personalLoan: 'Personal Loan',
        carLoan: 'Car Loan',
        homeLoanAmount: 'Home Loan Amount',
        interestRate: 'Interest Rate',
        loanTenure: 'Loan Tenure',
        loanEMI: 'Loan EMI',
        totalInterestPayable: 'Total Interest Payable',
        totalPayment: 'Total Payment (Principal + Interest)',
        breakUpTotalPayment: 'Break down of total payments',
        totalInterest: 'Total Interest',
        principalLoanAmount: 'Principal Loan Amount',
        year:'Yr',
      },
      info:{
        interestRate:'The interest rate is the amount a lender charges a borrower and is a percentage of the principal—the amount loaned. The interest rate on a loan is typically noted on an annual basis known as the annual percentage rate (APR).' ,
        loanEMI:'An equated monthly installment (EMI) is a fixed payment made by a borrower to a lender on a specified date of each month.',
        loanTenure:'Loan tenure refers to the length of time that will be taken by the borrower to repay the loan along with the interest.',
        principalLoanAmount:'Principal is most commonly used to refer to the original sum of money borrowed in a loan or put into an investment.',
        totalInterest:'Total Interest refers to the sum of the total interest amount to be paid to the lender.',
        totalPayment:'Total Payment includes the total amount that includes the original sum of the loan in addition to the total interest amount.',

      },
      admin: {
        welcomeBack: 'Welcome Back!',
        partners: 'Partners',
        products: 'Products',
        addNewPartner: 'Add a new partners',
        productCategory: 'Product Category',
        addNewProductCategory: 'Add a new product category',
        productDetails: 'Product Details',
        filters: 'Filters',
        addFilter: 'Add a filters',
        question: 'Question'
      },
      add: 'add'
    }
  },
  ar: {
    translation: {
      dashboard: 'لوحة القيادة',
      calculator: {
        amount: 'مقدار',
        homeLoan: 'قرض السكني',
        loanCalculator: 'حاسبة القروض',
        personalLoan: 'قرض شخصي',
        carLoan: 'قرض السيارة',
        homeLoanAmount: 'مبلغ القرض السكني',
        interestRate: 'سعر الفائدة',
        loanTenure: 'فترة القرض',
        loanEMI: 'القسط الشهري المعادل للقرض',
        totalInterestPayable: 'إجمالي الفوائد المستحقة الدفع',
        totalPayment: '(إجمالي السداد (المبلغ الأساسي + الفائدة',
        breakUpTotalPayment: 'تفصيل المدفوعات الإجمالية',
        totalInterest: 'مجموع الفوائد',
        principalLoanAmount: 'مبلغ القرض الأساسي',
        year:'سنوات'
      },
      info:{
        interestRate:'معدل الفائدة هو المبلغ الذي يفرضه المقرض على المقترض وهو نسبة مئوية من رأس المال. عادة ما يتم ملاحظة معدل الفائدة على القرض على أساس سنوي يُعرف باسم معدل النسبة السنوية (APR).' ,
        loanEMI:'القسط الشهري المتساوي (EMI) هو دفعة ثابتة يقوم بها المقترض للمقرض في تاريخ محدد من كل شهر',
        loanTenure:'تشير مدة القرض إلى المدة التي سيستغرقها المقترض لسداد القرض مع الفائدة',
        principalLoanAmount:'يشيع استخدام رأس المال للإشارة إلى المبلغ الأساسي للمال الذي تم اقتراضه في قرض أو وضعه في استثمار',
        totalInterest:'يشير إجمالي الفائدة إلى مجموع مبلغ الفائدة الإجمالي الذي يتعين دفعه للمقرض',
        totalPayment:'يشمل إجمالي السداد المبلغ الإجمالي الذي يتضمن المبلغ الأصلي للقرض بالإضافة إلى إجمالي مبلغ الفائدة',
        
      },
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
