import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Tooltip, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { debounce } from 'lodash';

import SliderCalculator from './SliderCalculator';
import { getCalculation, calculationLoanEMI, StringToNum, numToString } from 'utils/functions';

import styles from 'pages/calculatorPage/calculator.module.scss';
import InfoOutlined from '@mui/icons-material/InfoOutlined'


const TabPanel = ({ value, index, title }) => {
  const { t } = useTranslation();

  const [loanAmount, setLoanAmount] = useState(10000);
  const [interestRate, setInterestRate] = useState(3);
  const [loanTenure, setLoanTenure] = useState(5);
  const [totalInterestPayable, setTotalInterestPayable] = useState(1);
  const [loanEMI, setLoanEMI] = useState(1);
  const [totalPayment, setTotalPayment] = useState(1);

  
  const data = [
    {
      name: t('calculator.principalLoanAmount'),
      value: (totalInterestPayable / totalPayment) * 100
    },
    {
      name: t('calculator.totalInterest'),
      value: 100 - (totalInterestPayable / totalPayment) * 100
    }
  ];
  const COLORS = ['#F29469', '#69B5B5'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const debouncedCalculations = debounce(() => {
    setLoanEMI(
      Math.round(calculationLoanEMI(+(interestRate / 12 / 100), +loanTenure * 12, +loanAmount))
    );
    setTotalInterestPayable(getCalculation(loanAmount, interestRate, loanTenure));
  }, 300);

  useEffect(() => {
    debouncedCalculations();
  }, [loanAmount, interestRate, loanTenure]);

  useEffect(() => {
    setTotalPayment(loanEMI * loanTenure * 12);
  }, [loanEMI]);

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}>
      {value === index && (
        <Grid container flexDirection="column">
          <Grid item sx={{ p: 3 }}>
            <Grid container flexDirection="column">
              <SliderCalculator
                title={title}
                higher={index===2?100000:300000}
                suffix="BHD"
                step={1}
                onChange={(amount)=>setLoanAmount(StringToNum(amount))}
                defaultValue={10000}
                loanAmount
              />
              <SliderCalculator
                title={t('calculator.interestRate')}
                higher={20}
                suffix="%"
                step={0.5}
                onChange={setInterestRate}
                defaultValue={3}
                info={t('info.interestRate')}
              />
              <SliderCalculator
                title={t('calculator.loanTenure')}
                higher={30}
                suffix={t('calculator.year')}
                step={1}
                defaultValue={5}
                onChange={setLoanTenure}
                info={t('info.loanTenure')}
              />
            </Grid>
          </Grid>

          <Grid item sx={{ border: 'solid 1px #f2f2f2' }}>
            <Grid container className={styles.wrapGraphicsCalcucator}>
              <Grid
                item
                xs={6}
                sx={{ borderRight: 'solid 1px #f2f2f2' }}
                className={styles.item_first}>
                <Grid container flexDirection="column">

                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    sx={{ borderBottom: 'solid 1px #f2f2f2', p: 3 }}>
                      <Box display={'flex'} flexDirection='column'>

                        <Box display={'flex'} textAlign="center" justifyContent={'center'}>
                          <Typography mr={0} variant="bodyBig" fontSize={14} color="common.greyLight">
                            {t('calculator.loanEMI')}
                          </Typography>
                          {/* <Box mr={3}>
                            <Tooltip title={<span className={styles.tooltip}>{t('info.loanEMI')}</span>}>
                              <IconButton size="small" aria-label="info" >
                                <InfoOutlined color="secondary" fontSize="small"/>
                              </IconButton> 
                            </Tooltip>
                          </Box> */}
                        </Box>
                        <Box textAlign="center">
                          <Typography variant="bodyBig">
                            BHD {interestRate && loanTenure && loanAmount && numToString(loanEMI)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Tooltip title={<span className={styles.tooltip}>{t('info.loanEMI')}</span>}>
                          <IconButton size="small" aria-label="info" >
                            <InfoOutlined color="secondary" fontSize="small"/>
                          </IconButton> 
                        </Tooltip>
                      </Box>

                  </Grid>
                  

                  <Grid
                    item
                    xs={12}
                    display='flex'
                    justifyContent="center"
                    sx={{ borderBottom: 'solid 1px #f2f2f2', p: 3 }}>
                    <Box display={'flex'} flexDirection='column'>
                      <Box textAlign="center" display={'flex'} justifyContent="center">
                        <Typography mr={0} variant="bodyBig" fontSize={14} color="common.greyLight">
                          {t('calculator.totalInterest')}
                        </Typography>
                        {/* <Box mr={0}>
                            <Tooltip title={<span className={styles.tooltip}>{t('info.totalInterest')}</span>}>
                              <IconButton size="small" aria-label="info">
                                <InfoOutlined color="secondary" fontSize="small"/>
                              </IconButton> 
                            </Tooltip>
                          </Box> */}
                      </Box>
                      <Box textAlign="center">
                        <Typography variant="bodyBig">
                          BHD {numToString(totalInterestPayable)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box >
                      <Tooltip title={<span className={styles.tooltip}>{t('info.totalInterest')}</span>}>
                        <IconButton size="small" aria-label="info">
                          <InfoOutlined color="secondary" fontSize="small"/>
                        </IconButton> 
                      </Tooltip>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    justifyContent="center"
                    m="auto"
                    display='flex'
                    sx={{ p: 3 }}>
                    <Box display={'flex'} flexDirection='column'>

                      <Box textAlign="center" maxWidth={180} display={'flex'} justifyContent="center">
                        <Typography
                          mr={0}
                          m="auto"
                          variant="bodyBig"
                          fontSize={14}
                          color="common.greyLight">
                          {t('calculator.totalPayment')}
                        </Typography>
                        {/* <Box mr={0}>
                          <Tooltip title={<span className={styles.tooltip}>{t('info.totalPayment')}</span>}>
                            <IconButton size="small" aria-label="info">
                              <InfoOutlined color="secondary" fontSize="small"/>
                            </IconButton> 
                          </Tooltip>
                        </Box> */}
                      </Box>

                      <Box  textAlign="center">
                        <Typography variant="bodyBig">
                          BHD {numToString(+loanAmount + +totalInterestPayable)}
                        </Typography>
                      
                      </Box>
                     
                    </Box>
                     <Box>
                        <Tooltip title={<span className={styles.tooltip}>{t('info.totalPayment')}</span>}>
                          <IconButton size="small" aria-label="info">
                            <InfoOutlined color="secondary" fontSize="small"/>
                          </IconButton> 
                        </Tooltip>
                      </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} justifyContent="center" sx={{ p: 3 }} className={styles.item}>
                <Box textAlign="center">
                  <Typography mr={3} variant="bodyBig" fontSize={14}>
                    {t('calculator.breakUpTotalPayment')}
                  </Typography>
                </Box>

                <Box width="100%" height={200}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                      <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value">
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>

                  <Box
                    mt={1}
                    sx={{
                      border: 'solid 1px #f2f2f2',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '0 8px'
                    }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
                      <Box
                        sx={{ borderRadius: '50%', background: '#F29469', width: 10, height: 10 }}
                        mr="5px"
                      />
                      <Box display={'flex'} justifyContent="center">
                        <Typography variant="bodyBig" fontSize={10} color="common.greyLight">
                          {t('calculator.totalInterest')}
                        </Typography>
                        <Box mr={0}>
                          <Tooltip title={<span className={styles.tooltip}>{t('info.totalInterest')}</span>}>
                            <IconButton size="small" aria-label="info">
                              <InfoOutlined color="secondary" fontSize="small"/>
                            </IconButton> 
                          </Tooltip>
                        </Box>
                      </Box>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{ borderRadius: '50%', background: '#69B5B5', width: 10, height: 10 }}
                        mr="5px"
                      />
                      <Box display={'flex'} justifyContent="center">
                        <Typography variant="bodyBig" fontSize={10} color="common.greyLight">
                          {t('calculator.principalLoanAmount')}
                        </Typography>
                        <Box mr={0}>
                          <Tooltip title={<span className={styles.tooltip}>{t('info.principalLoanAmount')}</span>}>
                            <IconButton size="small" aria-label="info">
                              <InfoOutlined color="secondary" fontSize="small"/>
                            </IconButton> 
                          </Tooltip>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default TabPanel;

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  title: PropTypes.string
};
