import React, { useEffect } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as Logo } from 'assets/images/illustrations/logo-default.svg';
import TabPanel from '../../views/Calculator/TabPanel';

import styles from './calculator.module.scss';
import { selectDirection, setLanguage } from 'store/app/appSlice'
import {  useDispatch, useSelector } from 'react-redux'
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import {  useSearchParams } from 'react-router-dom'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const CalculatorPage = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
   const dispatch=useDispatch();
  const [searchParams]=useSearchParams()
  const lang=searchParams.get('lang')
  
  useEffect(()=>{
    dispatch(setLanguage(lang === 'ar' ? 'ar' : 'en'))
  },[])



  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin]
  });

  const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: []
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const direction = useSelector(selectDirection);
  // const theme = createTheme(ThemeOptions({ direction }));
  return (
    <>
      {/* <Box className={styles.wrap}>
        <FormGroup>
          <FormControlLabel control={  <Switch
            checked={lang !== 'en'}
            onChange={() => {
              dispatch(setLanguage(lang === 'en' ? 'ar' : 'en'))
            }}
          />}
          label="Arabic" 
          style={{color:'#9B9B9B'}}
          />

        </FormGroup>
     
      </Box>  */}
      <Box dir={direction}>
         <CacheProvider value={direction === 'rtl' ? cacheRtl : cacheLtr}>
           <Box className={styles.container} mt={5} >
        
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="secondary"
            textColor="primary"
            variant="fullWidth">
            <Tab
              label={
                <Typography variant="bodyBig" fontSize={16} className={styles.tabLabel}>
                  {t('calculator.homeLoan')}
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography variant="bodyBig" fontSize={16} className={styles.tabLabel}>
                  {t('calculator.personalLoan')}
                </Typography>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Typography variant="bodyBig" fontSize={16} className={styles.tabLabel}>
                  {t('calculator.carLoan')}
                </Typography>
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={value}
          index={0}
          title={`${t('calculator.homeLoan')} ${t('calculator.amount')}`}
        />
        <TabPanel
          value={value}
          index={1}
          title={`${t('calculator.personalLoan')} ${t('calculator.amount')}`}
        />
        <TabPanel
          value={value}
          index={2}
          title={`${t('calculator.carLoan')} ${t('calculator.amount')}`}
        />
      </Box>
      </CacheProvider>
     
            </Box>

    </>
  );
};

export default CalculatorPage;
