import React, { useState } from 'react';
import { Typography, Grid, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import FilterSlider from 'components/formControl/formCreator/FilterSlider';
import BaseInput from 'components/formControl/baseInput/BaseInput';
import styles from '../../pages/calculatorPage/calculator.module.scss';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/system'
import { numToString, StringToNum } from 'utils/functions'

const SliderCalculator = ({
  title,
  type,
  lower = 1,
  higher = 100,
  suffix,
  step,
  onChange,
  defaultValue = 1,
  info,
  loanAmount
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    setValue(typeof e === 'object' ? loanAmount?StringToNum(e.target.value):e.target.value : loanAmount?StringToNum(e):e);
    onChange(typeof e === 'object' ? e.target.value : e);
  };

  return (
    <Grid item xs={12} mb={5}>
      <Grid container alignItems="end" justifyContent="center" mb={2}>
        <Typography mr={info?0:3} variant="bodyBig" fontSize={16} className={styles.tabLabel}>
          {title}
          {type}
        </Typography>
       {info &&  <Box mr={3}>
          <Tooltip title={<span className={styles.tooltip}>{info}</span>}>
            <IconButton size="small" aria-label="info">
              <InfoOutlined color="secondary" fontSize="small"/>
            </IconButton> 
          </Tooltip>
        </Box>}
               
        <BaseInput
          className={styles.tabLabel}
          inputProps={{ style: { textAlign: 'center'} }}
          value={loanAmount?numToString(value):value}
          onChange={handleChange}
          type={loanAmount?"text":"number"}
        />
        {suffix}
      </Grid>

      <FilterSlider
        onChange={handleChange}
        lower={lower}
        higher={higher}
        suffix={suffix}
        value={value}
        step={step}
        loanAmount={!!loanAmount}
      />
    </Grid>
  );
};

export default SliderCalculator;

SliderCalculator.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  lower: PropTypes.number,
  higher: PropTypes.number,
  suffix: PropTypes.string,
  step: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  info:PropTypes.string,
  loanAmount:PropTypes.bool
};
