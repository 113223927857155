import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { CacheProvider } from '@emotion/react';
// import createCache from '@emotion/cache';
// import rtlPlugin from 'stylis-plugin-rtl';
import { Box } from '@mui/material';
// import { prefixer } from 'stylis';

import { selectDirection } from 'store/app/appSlice';
import ThemeOptions from 'theme/index';
import Routes from 'routes/routes';

// const cacheRtl = createCache({
//   key: 'muirtl',
//   stylisPlugins: [prefixer, rtlPlugin]
// });

// const cacheLtr = createCache({
//   key: 'muiltr',
//   stylisPlugins: []
// });

function App() {
  const direction = useSelector(selectDirection);
  const theme = createTheme(ThemeOptions({ direction }));

  return (
    <Box>
      {/* <CacheProvider value={direction === 'rtl' ? cacheRtl : cacheLtr}> */}
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      {/* </CacheProvider> */}
    </Box>
  );
}

export default App;
