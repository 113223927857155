import React from 'react';

import CalculatorPage from 'pages/calculatorPage/CalculatorPage';

import { useRoutes } from 'react-router-dom';

const publicRoutes = [
  {
    path: '/',
    element: <CalculatorPage />
  }
];

const routes = () => {
  const routes = [...publicRoutes];

  return useRoutes(routes);
};

export default routes;
